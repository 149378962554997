<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>

      <div>
        <v-col align="right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="application.admins.litter_id"
                small
                v-on="on"
                depressed
                color="blue lighten-4 blue--text"
                class="mr-2"
                :href="getLitterUrl(application.admins.hashed_id)"
                target="_blank"
              >
                <v-icon small>mdi-monitor-dashboard</v-icon>
              </v-btn>
            </template>
            <span>View Hidden Litter Page</span>
          </v-tooltip>
        </v-col>
      </div>

      <div>
        <v-row>
          <v-col cols="5">
            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">Contact</div>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>This is the Inputted Contact Details</p>
                    <div class="contact-card">
                      <div class="contact-card-body">
                        <div class="contact-card-title">
                          {{ application.admins.first_name }}
                          {{ application.admins.last_name }}
                        </div>

                        {{ application.admins.line_1 }}
                        {{ application.admins.line_2 }}
                        {{ application.admins.line_3 }}
                      </div>
                    </div></v-col
                  >
                  <v-col cols="12" v-if="application.admins.customer_id">
                    <div class="green--text lighten-3 mb-3">
                      A Contact has been successfully linked to this Application
                      Form.
                    </div>
                  </v-col>
                  <v-col v-else>
                    <div class="red--text lighten-3 mb-3">
                      A Contact hasn't been linked to this application.
                    </div>

                    <div v-if="this.found == 'none'">
                      <div class="red--text lighten-3 mb-3">
                        There are not matches for this customer in the database
                      </div>
                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        class="mr-2"
                        @click="openAddContact(application)"
                      >
                        Create a New Contact
                      </v-btn>
                    </div>

                    <div v-if="this.found == 'email'">
                      <div class="red--text lighten-3 mb-3">
                        A customer with this email address exists in the
                        database. Do you want to link this application form to
                        this Customer
                      </div>

                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        class="mr-2"
                        @click="openLinkToExising(application)"
                      >
                        Link to an Exising Contact
                      </v-btn>
                    </div>

                    <div v-if="this.found == 'match'">
                      <div class="red--text lighten-3 mb-3">
                        There is a possible match for this customer already in
                        the database
                      </div>

                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        class="mr-2"
                        @click="$refs.contactForm.openForm(application)"
                      >
                        Link to an Exising Contact
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="7">
            <div>
              <v-card>
                <v-tabs
                  background-color="grey lighten-3"
                  v-model="tab"
                  @change="onTabChange"
                >
                  <v-tab>General</v-tab>
                  <v-tab v-if="currentUserRole.name == 'VH Superadmin'"
                    >Maintenance</v-tab
                  >
                  <v-tab>Confirmation Email</v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentTab">
                  <v-tab-item>
                    <v-card>
                      <v-card-text
                        ><v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-simple-table class="mb-5 table" divider="true">
                                <tbody>
                                  <tr>
                                    <th colspan="5">Payments</th>
                                  </tr>

                                  <tr>
                                    <th>Deposit Request</th>
                                    <th>Deposit Received</th>
                                    <th>Email Confirmation</th>
                                    <th>Interim</th>
                                    <th>Final/Balance</th>
                                  </tr>

                                  <tr>
                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="
                                            fields.payments_deposit_request
                                          "
                                          @click="
                                            openSwitch(
                                              application,
                                              'payments_deposit_request'
                                            )
                                          "
                                          inset
                                          :error="
                                            errors.hasOwnProperty(
                                              'payments_deposit_request'
                                            )
                                          "
                                          :error-messages="
                                            errors['payments_deposit_request']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="fields.payment_deposit"
                                          inset
                                          @click="
                                            openSwitch(
                                              application,
                                              'payment_deposit'
                                            )
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="fields.email_confirmation"
                                          inset
                                          @click="
                                            openSwitch(
                                              application,
                                              'email_confirmation'
                                            )
                                          "
                                          :error="
                                            errors.hasOwnProperty(
                                              'email_confirmation'
                                            )
                                          "
                                          :error-messages="
                                            errors['email_confirmation']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          v-model="fields.payments_interim"
                                          inset
                                          color="green"
                                          @click="
                                            openSwitch(
                                              application,
                                              'payments_interim'
                                            )
                                          "
                                          :error="
                                            errors.hasOwnProperty(
                                              'payments_interim'
                                            )
                                          "
                                          :error-messages="
                                            errors['payments_interim']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="
                                            fields.payments_final_balance
                                          "
                                          @click="
                                            openSwitch(
                                              application,
                                              'payments_final_balance'
                                            )
                                          "
                                          inset
                                          :error="
                                            errors.hasOwnProperty(
                                              'payments_final_balance'
                                            )
                                          "
                                          :error-messages="
                                            errors['payments_final_balance']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>

                              <v-simple-table class="mb-5 table">
                                <tbody>
                                  <tr>
                                    <th>Chosen Litter</th>
                                    <td>
                                      <v-autocomplete
                                        v-model="fields.litter_id"
                                        :items="litters"
                                        item-text="name"
                                        item-value="id"
                                        class="mb-5"
                                        hide-details
                                        outlined
                                        clearable
                                        background-color="white"
                                        @change="changeLitter()"
                                        :error="
                                          errors.hasOwnProperty('litter_id')
                                        "
                                        :error-messages="errors['litter_id']"
                                      ></v-autocomplete>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>First Name</th>
                                    <td>
                                      {{ application.admins.first_name }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Last Name</th>
                                    <td>
                                      {{ application.admins.last_name }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Status</th>
                                    <td>
                                      <v-select
                                        class="mt-5"
                                        label="Status"
                                        :items="statuses"
                                        v-model="fieldsStatus.status"
                                        item-text="name"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        background-color="white"
                                        @change="changeStatus()"
                                      ></v-select>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item v-if="currentUserRole.name == 'VH Superadmin'">
                    <v-card-title class="d-flex justify-start align-center">
                      <div class="flex-grow-1"></div>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            depressed
                            color="blue lighten-4 blue--text"
                            v-on="on"
                            @click="
                              $refs.maintenanceForm.openForm(application.admins)
                            "
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Maintenance</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-simple-table class="mb-5 table" divider="true">
                      <tbody>
                        <tr>
                          <th>Contact ID</th>
                          <td>
                            <div v-if="application.admins.customer_id">
                              {{ application.admins.customer_id }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Hashed ID</th>
                          <td>{{ application.admins.hashed_id }}</td>
                        </tr>
                        <tr>
                          <th>Breed</th>
                          <td>
                            <div v-if="application.admins.breed">
                              {{ application.admins.breed.name }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card>
                      <v-card-text>
                        <v-form
                          @submit.prevent="saveForm"
                          method="post"
                          id="message-form"
                        >
                          <tiptap
                            class="mb-5"
                            label="Message*"
                            v-model="fieldsMessage.message"
                            :error="errors.hasOwnProperty('message')"
                            :error-messages="errors['message']"
                          />
                        </v-form>

                        <v-btn
                          color="green"
                          text
                          type="submit"
                          :loading="loading"
                          form="message-form"
                          >Send Confirmation Email</v-btn
                        >
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">Application Details</div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.applicationForm.openForm(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>
              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <th>First Name</th>
                    <td>
                      <div>{{ application.admins.first_name }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>
                      <div>{{ application.admins.last_name }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>
                      {{ application.admins.line_1 }}
                      {{ application.admins.line_2 }}
                      {{ application.admins.line_3 }}
                    </td>
                  </tr>
                  <tr>
                    <th>Daytime telephone</th>
                    <td>
                      <div>
                        {{ application.admins.daytime_telephone }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Evening telephone</th>
                    <td>
                      <div>
                        {{ application.admins.evening_telephone }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>
                      <div>
                        {{ application.admins.phone }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>
                      <div>
                        {{ application.admins.email }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Desired Litter</th>
                    <td>
                      <div>{{ application.desired_litter }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Desired timeframe for puppy collection</th>
                    <td>
                      <div>{{ application.suitable_collection_timeframe }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Any dates, weeks or months that are unsuitable for a puppy
                      to arrive at your home
                    </th>
                    <td>
                      <div>{{ application.unsuitable_collection_dates }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>Must your puppy be non-shedding?</th>
                    <td>
                      <div>{{ application.non_shedding ? "Yes" : "No" }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Are you open to having a puppy with any coat colour?
                    </th>
                    <td>
                      <div>
                        {{ application.coat_colour_important ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <th>
                      If you have a strong preference for coat colour and you
                      would consider this a 'deal-breaker', we will place your
                      reservation on a single-colour litter where we know in
                      advance of the litter being born that all of the puppies
                      are going to be your chosen colour. Should we place your
                      reservation on a single-colour litter?
                    </th>
                    <td>
                      <div>?? {{ application.single_colour_litter? "Yes" : "No" }} ???</div>
                    </td>
                  </tr> -->
                  <tr>
                    <th>Gender Preference</th>
                    <td>
                      <div>{{ application.any_gender ? "Yes" : "No" }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>What are you gender preferences?</th>
                    <td>
                      <div>{{ application.gender_preference }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Does anyone in your household have allergies or asthma?
                    </th>
                    <td>
                      <div>
                        {{
                          application.does_anyone_in_your_household_have_allergies_or_asthma
                        }}
                        ???
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>If yes, please tell us more</th>
                    <td>
                      <div>{{ application.if_yes_please_tell_us_more }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Please list each family/household member's name,
                      occupation and age (where appropriate)
                    </th>
                    <td>
                      <div>{{ application.household_members }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Please detail the primary and secondary care-givers'
                      working schedule
                    </th>
                    <td>
                      <div>{{ application.carer_schedule }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Please detail provisions for the care of the dog when you
                      are at work or not at home
                    </th>
                    <td>
                      <div>{{ application.provisions_for_dog }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Do you have any under 18 year olds living at home or
                      visiting regularly?
                    </th>
                    <td>
                      <div>{{ application.under_18s ? "Yes" : "No" }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Please list the children's genders and ages below</th>
                    <td>
                      <div>{{ application.under_18s_details }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Have the children been exposed to dogs much?</th>
                    <td>
                      <div>
                        {{ application.under_18s_exposure ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Was their reaction confident?</th>
                    <td>
                      <div>
                        {{
                          application.under_18s_confident_reaction
                            ? "Yes"
                            : "No"
                        }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Does anybody in your household have a disability or
                      particular need which may need to be taken into
                      consideration when matching a puppy with your family?
                    </th>
                    <td>
                      <div>
                        {{ application.household_disability ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Please provide as much detail as you can about the
                      disability or particular need so that we can match you
                      with a puppy that will be a good fit
                    </th>
                    <td>
                      <div>
                        {{ application.household_disability_information }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Has an adult living in your household owned a dog before?
                    </th>
                    <td>
                      <div>{{ application.adult_owner_status }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Do you have any animals currently living on your property?
                    </th>
                    <td>
                      <div>
                        {{ application.additional_animals ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Please provide details of your other pets/animals</th>
                    <td>
                      <div>{{ application.additional_animals_details }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Please detail you plans for training your puppy. Who,
                      when, where, how long for?
                    </th>
                    <td>
                      <div>{{ application.puppy_training_details }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>Do you have a fully enclosed garden?</th>
                    <td>
                      <div>
                        {{ application.enclosed_garden ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      How will you contain your puppy when s/he needs to go out
                      to the toilet first thing in the morning/last thing at
                      night?
                    </th>
                    <td>{{ application.puppy_needs_toilet }}</td>
                  </tr>

                  <tr>
                    <th>Where will your dog be living?</th>
                    <td>
                      <div>{{ application.dog_living_conditions }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Do you often have clients, regular scheduled meetings or
                      strangers in your home?
                    </th>
                    <td>
                      <div>
                        {{ application.regular_visitors ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Have you ever had to re-home a pet or relinquish a pet to
                      an animal shelter?
                    </th>
                    <td>
                      <div>
                        {{ application.relinquish_pet_prior ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Is this puppy being purchased as a gift?</th>
                    <td>
                      <div>{{ application.puppy_a_gift ? "Yes" : "No" }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Is the puppy a gift for someone living in your household?
                    </th>
                    <td>
                      <div>
                        {{
                          application.puppy_gift_for_household ? "Yes" : "No"
                        }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Does the person know about it?</th>
                    <td>
                      <div>
                        {{
                          application.puppy_gift_prior_knowledge ? "Yes" : "No"
                        }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Is there anything else you think we should know?</th>
                    <td>
                      <div>{{ application.anything_else_to_tell }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>How did you find out about us?</th>
                    <td>
                      <span
                        v-for="(item, index) in application.found_lhl"
                        :key="index"
                        >{{ item }},
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th colspan="3">What size of puppy are you looking for?</th>
                  </tr>

                  <tr>
                    <td>First Choice</td>
                    <td>Second Choice</td>
                    <td>Third Choice</td>
                  </tr>
                  <tr>
                    <td>
                      {{ application.size_first_choice }}
                    </td>
                    <td>
                      {{ application.size_second_choice }}
                    </td>
                    <td>
                      {{ application.size_third_choice }}
                    </td>
                  </tr>

                  <tr>
                    <th colspan="3">
                      What coat type would you like your puppy to have?
                    </th>
                  </tr>

                  <tr>
                    <td>First Choice</td>
                    <td>Second Choice</td>
                    <td>Third Choice</td>
                  </tr>

                  <tr>
                    <td>
                      <div>{{ application.coat_type_first_choice }}</div>
                    </td>
                    <td>
                      <div>{{ application.coat_type_second_choice }}</div>
                    </td>
                    <td>
                      <div>{{ application.coat_type_third_choice }}</div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <MaintenanceForm ref="maintenanceForm" />
    <ContactForm ref="contactForm" type="puppy" />
    <ApplicationForm ref="applicationForm" />

    <v-dialog scrollable v-model="exising.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Link to Contact</v-card-title>
        <v-card-text
          >Are you sure you wish to link to exising contact?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetAddContact"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="exising.loading"
            @click="saveExisingContact"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="newContact.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Create Contact</v-card-title>
        <v-card-text>Are you sure you wish to create contact?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetAddContact"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="newContact.loading"
            @click="saveAddContact"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";
import MaintenanceForm from "../components/MaintenanceForm.vue";
import ContactForm from "../components/ContactForm.vue";
import ApplicationForm from "../components/ApplicationFchForm.vue";

export default {
  components: {
    Tiptap,
    MaintenanceForm,
    ContactForm,
    ApplicationForm,
  },

  data() {
    return {
      found: null,
      newContact: {
        dialog: false,
        loading: false,
        application: {},
      },
      exising: {
        dialog: false,
        loading: false,
        application: {},
      },
      fields: {
        email_confirmation: false,
        payments_deposit_request: false,
        payment_deposit: false,
        payments_interim: false,
        payments_final_balance: false,
        litter_id: null,
      },
      statuses: [
        { name: "Pending", value: "Pending" },
        { name: "Active", value: "Active" },
        { name: "Complete", value: "Complete" },
        { name: "Rejected", value: "Rejected" },
        { name: "On Hold", value: "On Hold" },
        { name: "Unresponsive", value: "Unresponsive" },
      ],
      fieldsStatus: {
        status: null,
      },

      fieldsMessage: {
        message: null,
      },
      loading: false,
      errors: {},
      currentTab: null,
      tab: null,
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Applications",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-applications-puppy",
            params: { id: this.$route.params.id },
          },
        },
      ],
    };
  },

  mounted: function () {
    if (this.application.custommer_id == null) {
      this.$store
        .dispatch("lhl/applications/checkCustomerExist", {
          appId: this.$route.params.id,
          applicationId: this.$route.params.applicationId,
          fields: {
            first_name: this.application.admins.first_name,
            last_name: this.application.admins.last_name,
            email: this.application.admins.email,
          },
        })
        .then((resp) => {
          this.found = resp.data.status;
        });
    }

    this.fields.payment_deposit = this.application.admins.payment_deposit;
    this.fields.email_confirmation = this.application.admins.email_confirmation;
    this.fields.payments_deposit_request =
      this.application.admins.payments_deposit_request;
    this.fields.payments_final_balance =
      this.application.admins.payments_final_balance;
    this.fields.payments_interim = this.application.admins.payments_interim;

    this.fields.litter_id = this.application.admins.litter_id;

    this.fieldsStatus.status = this.application.admins.status;

    this.fieldsMessage.message = this.application.admins.message
      ? this.application.admins.message
      : this.defaultMessageText;
  },

  methods: {
    openAddContact(application) {
      this.newContact.application = application;
      this.newContact.dialog = true;
    },

    openLinkToExising(application) {
      this.exising.application = application;
      this.exising.dialog = true;
    },

    saveExisingContact() {
      this.exising.loading = true;

      this.$store
        .dispatch("lhl/applications/exisingContact", {
          appId: this.$route.params.id,
          applicationId: this.exising.application.id,
        })
        .then(() => {
          this.resetExistingContact();
        })
        .catch(() => {
          this.exising.loading = false;
        });
    },

    saveAddContact() {
      this.newContact.loading = true;

      this.$store
        .dispatch("lhl/applications/newContact", {
          appId: this.$route.params.id,
          applicationId: this.newContact.application.id,
        })
        .then(() => {
          this.resetAddContact();
        })
        .catch(() => {
          this.newContact.loading = false;
        });
    },

    resetAddContact() {
      this.newContact.dialog = false;
      this.newContact.contact = {};
      this.newContact.loading = false;
    },

    resetExistingContact() {
      this.exising.dialog = false;
      this.exising.contact = {};
      this.exising.loading = false;
    },

    onTabChange() {
      this.currentTab = this.tab;
    },

    getLitterUrl(unique_id) {
      return (
        process.env.VUE_APP_LHL_FORMS_URL + "/hidden-litter-page/" + unique_id
      );
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        applicationId: this.$route.params.applicationId,
        fields: this.fieldsMessage,
      };

      payload.fields.type = "message";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
      this.fieldsMessage = {
        message: null,
      };
    },

    openSwitch(application, field) {
      this.loading = true;
      this.errors = {};

      this.fields[field] = application.admins[field] ? 0 : 1;

      this.fields.type = "switch";

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        applicationId: application.id,
      };

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {})
        .catch();
    },

    getLitterUrlEmail() {
      if (this.application.admins.litter) {
        return (
          "<a target='_blank' href=" +
          process.env.VUE_APP_LHL_FORMS_URL +
          "/hidden-litter-page/" +
          this.application.admins.litter.unique_id +
          "> by following this Link</a>"
        );
      } else {
        return "";
      }
    },

    changeLitter() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        applicationId: this.$route.params.applicationId,
      };

      this.fields.type = "litter";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {})
        .catch();
    },

    changeStatus() {
      this.loading = true;
      this.errors = {};

      this.fieldsStatus.type = "status";

      let payload = {
        appId: this.$route.params.id,
        fields: this.fieldsStatus,
        applicationId: this.$route.params.applicationId,
      };

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {})
        .catch();
    },
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    defaultMessageText() {
      return (
        `<p>Hi ` +
        this.application.admins.first_name +
        `,

<p>This email is to confirm that your reservation has been assigned to an upcoming litter based upon the details you provided when completing your online application.</p>

<p>You can keep updated on the status of your reservation and see full details of the relevant litter ` +
        this.getLitterUrlEmail() +
        ` This takes you to a private page on our website which can only be accessed by you, via this link. This is your one centralised place for all of the information you need along with updates and photos of the puppies!</p>

<p>We would like to remind you that whilst we use our experience to estimate the number, gender, colour and coat type of puppies in each litter, nature has full control! With this in mind, we ask that you be as open-minded and flexible as possible.</p>

<p>When the puppies are born, we will be focusing our efforts on ensuring that both the mother and puppies are thriving. We spend a great deal of time ensuring that everyone gets their fair share on the ‘milk bar’ and giving special care to any little ones that might need extra support during their first few days. Sometimes this means round the clock care! It can therefore take up to a week for us to announce the birth and make details and photos available on the litter page (link above), so we thank you for your patience whilst we are caring for the new-born puppies.</p>

<p>Approximate Schedule of Events</p>

<p>1 week old – birth announced on the litter page with photos, information and dates for the puppy party and puppy collection</p>

<p>3 weeks old – updated photos posted on the litter page</p>

<p>4 weeks old – we will invoice you for the next instalment (£1,000)</p>

<p>6 weeks old – the puppy party/updated photos posted on the litter page. At the moment we cannot run puppy parties so we will send you additional videos at this stage alongside the pictures.</p>

<p>7 weeks old – we conduct the temperament evaluation and within a few days of that the puppies are allocated to families and we notify you by email/we will invoice you for the final balance due on your puppy</p>

<p>8-9 weeks old – puppy collection day!</p>

<p>We ask that you respect our policies and process. We have refined it over many years of experience and it ensures that you can be involved in the puppies’ progress, whilst making sure that we are able to dedicate the vast majority of our time to providing the best care for our dogs and puppies!</p>

<p>If the puppy party can take place then this will happen when the litter is approximately 6 weeks old; the date will be finalised once the litter is born. You will have at least 5 weeks’ notice and there will not be any flexibility on the scheduled date and time. We do a lot of cleaning and disinfection both before and after the visit to safeguard our puppies from infection, so scheduling alternative visit appointments is not possible. The puppy party will take place either on a Friday or on a weekend date to ensure that as many clients with reservations on the litter can attend as possible. Only immediate family members who live in your household are allowed to visit at this time. Children are welcome, but we ask that you prepare them for the visit and ensure they behave appropriately with our dogs and puppies. The visit will last 1.5 hours and you will have the opportunity to meet and spend time with the mother, and all of her puppies.</p>

<p>When the litter is 7 weeks old, we will conduct a temperament evaluation of all the puppies. The evaluation uses a number of indicators to determine individual puppies’ behavioural tendencies. Once this evaluation is complete, we allocate the puppies to the reservations on the litter. We do our very best to allocate everyone a puppy that matches all of their preferences, but we do place the most importance on temperament. We will not forego temperament in order to please a client on their colour or gender preferences, as it is unfair to place a puppy with a home which is not well-suited.<br/>
No client is under any obligation to accept the puppy they are allocated but must understand that they may need to wait for a future litter in the event they choose not to accept the allocated puppy. We also reserve the right to select puppies for future breeding purposes prior to allocating puppies to clients. If a prospective breeding puppy is required from the litter, this may delay the allocation process.</p>

<p>Our puppies go to their forever homes when they are between 8 and 9 weeks old. The puppy collection date will be scheduled after the puppies are born and will be posted on the litter page. We will make every effort to schedule it for a Friday where possible. We do not allow delayed collections as we are not set up to provide the individual care and attention required for a solo 9+ week old puppy for additional time. This is a key time when puppies really need lots of one-to-one socialisation, training and bonding with their forever families. If you need to delay welcoming your puppy then you might want to look into enrolling your puppy into one or two weeks of our puppy school.</p>

<p>If for some reason there is not a suitable puppy in the litter for you, you will be able to defer your reservation fee to another upcoming litter. If this scenario occurs, we always use the date that reservations were placed in order to determine the order in which clients appear on the reservation list. Please note this does also mean that if a client who placed a reservation on an earlier litter before you is disappointed for whatever reason, their reservation may roll forward which could then push your reservation down the list a little. Unfortunately, there is no better way to manage this – we’re very much at the mercy of nature, and we ask that everyone is mindful of that.</p>

<p>Please do not be alarmed if you do not see the litter you’ve reserved a puppy from on our website as we do not advertise litters which already have a full reservation list. If our plans change, we will get in touch and let you know.</p>

<p>We will be in touch when we have news for you! In the meantime, please check out the litter page (link above), Like our <a href="https://www.facebook.com/LomondHills/">Facebook page</a> and check out our <a href="https://www.instagram.com/lomondhillslabradoodles/">Instagram page</a>, where you will be able to see lots of photos, and investigate our website where there is a wealth of useful information on training, coat care and the like! If you’re looking for recommendations on reading material – we suggest “The Perfect Puppy” by Gwen Bailey and "Easy Peasy Puppy Squeezy" by Steve Mann. These books will help you to get off to a great start with your new family member.</p>`
      );
    },

    application() {
      return this.$store.getters["lhl/applications/get"];
    },

    litters() {
      let litters = this.$store.getters["lhl/litters/all"];

      return litters.filter(
        (c) => c.status == "Planned" || c.status == "Due" || c.status == "Born"
      );
    },
  },
};
</script>
