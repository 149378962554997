<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Maintenance</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-autocomplete
            label="Contact"
            v-model="fields.customer_id"
            :items="customers"
            item-text="full_name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          >
          </v-autocomplete>

          <v-text-field
            label="Hashed Id"
            v-model="fields.hashed_id"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('hashed_id')"
            :error-messages="errors['hashed_id']"
          ></v-text-field>

          <v-select
            label="Breed"
            v-model="fields.breed_id"
            :items="breeds"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('breed_id')"
            :error-messages="errors['breed_id']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    applicationId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      fields: {
        hashed_id: null,
        customer_id: null,
        breed_id: null,
      },
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },
    customers() {
      let customers = this.$store.getters["lhl/customers/all"];

      return customers;
    },
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;

        this.fields.hashed_id = application.hashed_id;
        this.fields.customer_id = application.customer_id;
        this.fields.breed_id = application.breed_id;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        applicationId: this.$route.params.applicationId,
        fields: this.fields,
      };

      payload.fields.type = "maintenance";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.hashed_id = null;
      this.fields.customer_id = null;
      this.fields.breed_id = null;
    },
  },
};
</script>
