<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Contact</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="contact-form">
          <v-autocomplete
            label="Customer"
            v-model="fields.customer_id"
            :items="customers"
            :item-text="(item) => `${item.full_name} ${item.email}`"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          >
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetExistingContact()"
          >Close</v-btn
        >
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="contact-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    applicationId: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      dispatch: null,
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      fields: {
        customer_id: null,
      },
    };
  },

  computed: {
    customers() {
      let customers = this.$store.getters["lhl/customers/all"];

      return customers;
    },
  },

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;

        this.fields.customer_id = application.customer_id;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        applicationId: this.$route.params.applicationId,
        fields: this.fields,
      };

      payload.fields.type = "existing_name";

      if (this.type == "puppy") {
        this.dispatch = "lhl/applications/exisingContact";
      } else {
        this.dispatch = "lhl/applications/exisingFchContact";
      }

      this.$store
        .dispatch(this.dispatch, payload)
        .then(() => {
          this.resetExistingContact();
        })
        .catch(() => {
          this.dialog.loading = false;
        });
    },

    resetExistingContact: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.customer_id = null;
    },
  },
};
</script>
